
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























$bp: m;

.contact-map {
  @include mq($until: $bp) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  @include mq($bp) {
  }
}

.contact-map__search-container {
  position: sticky;
  z-index: 99;
  top: $spacing * 2.5;

  @include mq($bp) {
    position: relative;
    top: 0;
    margin-top: var(--spacing-l);
  }
}

.contact-map__search {
  @extend %shadow;
  @extend %border-radius-small;
  @extend %fw-light;

  padding: $spacing;
  background-color: $c-white;
  border: 0.1rem solid $c-gray-light;
  transform: translateY($spacing);

  @include mq($bp) {
    position: absolute;
    top: $spacing;
    left: $spacing;
    width: 52rem;
    padding: $spacing * 2;
    transform: none;
  }
}

.contact-map__input {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
  border: none;
}

.contact-map__input__label {
  @extend %text-uppercase;
  @extend %fw-bold;

  font-size: 1.3rem;
  letter-spacing: 0.05em;
}

.contact-map__input__field {
  box-sizing: border-box;
  margin-top: math.div($spacing, 2);
  padding: $spacing * 0.5 $spacing;
  font-weight: 300;
  border: 1px solid $c-gray-light;
  border-radius: 4px;
}

.contact-map__overlay__info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  & > * {
    width: 50%;
    min-width: 30rem;
  }
}

.contact-map__overlay__headline {
  margin-bottom: 1rem;

  @include mq($bp) {
    margin-bottom: 2rem;
  }
}

.contact-map__overlay__title {
  margin-bottom: 1.5rem;

  @include mq($bp) {
    margin-bottom: 4rem;
  }
}

.contact-map__overlay__message {
  margin-bottom: 4rem;

  @include mq($bp) {
    margin-bottom: 5rem;
  }
}

.contact-map__overlay__phone,
.contact-map__overlay__email,
.contact-map__overlay__address {
  position: relative;
  margin-bottom: 2rem;
  padding-bottom: 1rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 2rem;
    height: 1px;
    background: $c-gray-light;
  }

  @include mq($bp) {
    margin-bottom: 4rem;
  }
}

.contact-map__overlay__link {
  margin-top: 2rem;
  color: $c-mustard;
  text-decoration: underline;

  &:hover {
    color: darken($c-mustard, 10);
  }
}

.contact-map-container {
  width: 100%;
  aspect-ratio: 1;

  ::v-deep {
    img {
      filter: none;
    }
  }

  @include mq($bp) {
    aspect-ratio: 16/9;
  }
}

.contact-map__cta {
  background: $c-gray-darkest;
}

.contact-map__shop {
  width: inside-col(22, 24);
  margin-left: inside-col(1, 24);
  padding: 0 inside-col(1, 24) !important; /* stylelint-disable-line declaration-no-important */

  @include mq(xxxl) {
    padding: 0 inside-col(2, 24) !important; /* stylelint-disable-line declaration-no-important */
  }
}

.contact-map__showroom__select {
  @extend %text-center;

  margin-top: $spacing * 2;
  color: $c-medium-grey;
}
