
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































.contact-result {
  padding-top: $spacing * 4;

  ::v-deep {
    .g-intro {
      max-width: 100%;
      line-height: 1.25;
    }

    .cpt-prepare {
      @include mq(l) {
        margin-top: 4rem;
      }
    }

    .flexible-picture {
      .picture__container {
        @include mq(l) {
          padding-bottom: 30%;
        }
      }
    }

    .flexible-prepare {
      padding-bottom: 0;
    }
  }

  @include mq(m) {
    padding-top: $spacing * 5;
  }

  @include mq(xl) {
    padding-top: $spacing * 8;
  }
}

.contact-result__error {
  color: $c-dev-error;
}
