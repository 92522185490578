
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































































































































































































































.step-personal {
  display: flex;
  flex-direction: column;
}

.step-personal__subtitle {
  display: flex;
  align-items: center;
  order: -1;
  margin-bottom: var(--spacing-s);
}

.step-personal__subtitle__icon {
  flex-shrink: 0;
  width: 4rem;
  height: auto;
  margin-right: $spacing * 0.4;
  color: var(--c-primary);

  @include mq(l) {
    width: 5.4rem;
    margin-right: $spacing * 0.75;
  }
}

.form {
  @include mq(l) {
    padding-right: col(4, 12);
  }
}

::v-deep .form-label {
  @include mq(l) {
    display: block;
    margin-bottom: 0;
    line-height: 2rem;
  }
}

::v-deep .form-sidetooltip {
  @include mq(l) {
    top: 3rem;
  }
}

.form__fieldset {
  margin-left: 0;
  padding: 0;
  border: 0;

  .select,
  .textarea {
    @include mq('s') {
      grid-column: 1 / 3;
    }
  }
}

.form__fieldset,
.form__confirmation {
  @include fluid(
    column-gap,
    (
      xxs: 10px,
      xxl: 35px,
    )
  );
  @include fluid(
    row-gap,
    (
      xxs: 20px,
      xxl: 25px,
    )
  );

  margin-top: 0;

  @include mq('s') {
    display: grid;
    grid-template: auto / 1fr 1fr;
  }
}

.form__input {
  position: relative;
  margin-top: $spacing;

  &.full {
    grid-column: 1 / 3;
  }

  .form-select {
    z-index: 1;
  }

  &:first-child {
    margin-top: 0;
  }

  @include mq('s') {
    &:nth-child(2) {
      margin-top: 0;
    }
  }
}

.form__confirmation {
  margin-left: 0;
  padding: 0;
  border: 0;

  @include mq('s') {
    display: block;
  }

  @include mq('xl') {
    grid-template: auto / 1fr 300px;
    row-gap: 0;
  }
}

.form__confirmation__recaptcha {
  @include mq('xl') {
    grid-row: 2 / 2;
    grid-column: 1 / 2;
  }
}

.form-label__required {
  color: $c-mustard;
}

.form__feedback {
  @extend %border-radius-small;

  position: static;
  justify-content: center;
  width: 100%;
  padding: $spacing $spacing $spacing * 1.2;
  font-size: 1.5rem;
  border: 1px solid $c-dev-error;

  & + & {
    margin-top: $spacing;
  }
}

.form__send {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: $spacing * 2;
  padding: $spacing 0;
  border: 0;
  border-top: 1px solid $c-gray-light;

  @include mq(s) {
    flex-direction: row;
    justify-content: space-between;
  }

  @include mq(l) {
    padding: $spacing * 2.4 0;
  }
}

.form__send__action {
  margin-top: $spacing;

  & + & {
    margin-top: 0;
  }

  @include mq(s) {
    margin-top: 0;
  }
}
