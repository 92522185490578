
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































.form-label {
  @extend %ff-alt;

  margin-bottom: 1rem;
  color: $c-gray-dark;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  @include mq(m) {
    font-size: 1.3rem;
  }
}

.form-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
  border: 1px solid $c-light-grey;
  border-radius: 4px;

  @include mq(s) {
    flex-direction: row;
  }
}

.moodboard__picture {
  flex-shrink: 0;
  overflow: hidden;
  width: 100%;
  background-color: $c-mustard;

  @include mq(s) {
    width: 14rem;
  }
}

.moodboard__picture__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.5);
}

.moodboard__content {
  padding: 3rem 4rem;
  font-size: 1.4rem;
  line-height: 1.2;
}

.moodboard__title {
  margin-bottom: 0.5rem;
  font-weight: 700;
}
