
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

































































































































































































































































































































































.contact {
  padding-bottom: var(--spacing-s);
}

.contact__heading {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-m);

  @include mq(l) {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: start;
    gap: 0 $spacing * 2;
  }
}

.contact__intro {
  margin-top: var(--spacing-s);

  @include mq(l) {
    grid-row: 2/3;
    grid-column: 1/2;
    max-width: 50em;
  }
}

.contact__pill {
  order: -1;
  margin: 0 auto $spacing * 1.6;

  @include mq(l) {
    grid-row: 1/3;
    grid-column: 2/3;
    order: initial;
    margin: 0;

    &[href]:hover,
    &[href]:focus-visible {
      transform: scale(1.02);
    }
  }

  @include mq(xl) {
    max-width: 35rem;
  }
}

.contact__benefits {
  margin-top: var(--spacing-l);
}
