
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















































































































































































































.step-showroom__title {
  @extend %text-center;
}

.step-showroom__results {
  margin-top: var(--spacing-m);
}

.step-showroom__all__list {
  @extend %list-nostyle;
}

// Transition-group
.showrooms-enter-active,
.showrooms-leave-active {
  transition: opacity 0.2s;
}

.showrooms-enter, .showrooms-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
}
