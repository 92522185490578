
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































.contact-step {
  position: relative;
}

.contact-step__link {
  position: relative;
  display: block;
  color: $c-gray-light;
  text-decoration: none;

  &.router-link-active {
    pointer-events: none;
  }

  &[href] {
    color: $c-dark-grey;
  }
}

.contact-step__title {
  @extend %fw-bold;

  color: currentColor;
  font-family: $ff-alt;
  font-size: 3rem;
  font-style: normal;
}

.contact-step__progress {
  @extend %fw-bold;
  @extend %text-uppercase;

  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-top: math.div($spacing, 2);
  font-family: $ff-alt;
  font-size: 1.3rem;
  font-style: normal;
  letter-spacing: 0.1rem;
}

.contact-step__progress__label {
  flex-shrink: 0;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: color 0.2s;

  .router-link-active &,
  .contact-step__link[href]:hover &,
  .contact-step__link[href]:focus & {
    color: $c-mustard;
  }

  ::v-deep em {
    font-style: normal;
  }
}

.contact-step__progress__bar {
  position: relative;
  flex-basis: 100%;
  height: 0.2rem;
  margin-left: $spacing * 1.5;
  background-color: currentColor;

  &::after {
    @include get-all-space;

    content: '';
    display: block;
    transform: scaleX(0);
    transform-origin: right;
    background-color: $c-mustard;
    transition: transform 0.3s $ease-in-out-quad;

    .contact-step__link[href]:hover &,
    .contact-step__link[href]:focus & {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  .router-link-active & {
    background-color: $c-mustard;

    &::after {
      display: none;
    }
  }
}
