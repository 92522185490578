
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































































.step-container,
[class*='step-container--'] {
  margin-top: var(--spacing-l);
}

.step-container__heading {
  @include mq(l) {
    &.has-info {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.step-container__heading__title {
  @include mq(l) {
    .has-info & {
      grid-row: 1/2;
      grid-column: 1/2;
      margin: 0;
    }
  }
}

.step-container__heading__info {
  @extend %border-radius-small;

  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 2.4rem 1fr;
  grid-gap: var(--spacing-s) var(--spacing-xs);
  margin: var(--spacing-s) 0;
  padding: var(--spacing-s);
  border: 0.1rem solid var(--c-primary);

  @include mq(l) {
    grid-gap: $spacing var(--spacing-xs);
    grid-row: 1/2;
    grid-column: 2/3;
    margin: 0;
  }
}

.step-container__heading__info__title {
  grid-row: 1/2;
  grid-column: 2/3;
  align-self: flex-end;
  font-size: 1.8rem;
}

.step-container__heading__info__icon {
  grid-row: 1/2;
  grid-column: 1/2;
  align-self: center;
  width: 2.4rem;
  height: 2.4rem;
  color: var(--c-primary);
}

.step-container__heading__info__text {
  grid-row: 2/3;
  grid-column: 1/3;

  /* stylelint-disable selector-max-combinators, selector-max-compound-selectors */
  ::v-deep ol li {
    padding-left: 3.2rem;

    &::before {
      top: 0;
      width: 2.4rem;
      height: 2.4rem;
      color: var(--c-primary);
      font-size: 1.1rem;
    }
  }

  ::v-deep li + li {
    margin-top: $spacing * 0.4;
  }

  @include mq(l) {
    ::v-deep ol li {
      &::before {
        top: 0.4em;
      }
    }
  }
  /* stylelint-enable selector-max-combinators, selector-max-compound-selectors */
}

.step-container__content {
  @include mq(l) {
    .has-info & {
      grid-column: 1/3;
    }
  }
}
