
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































































.form-sidetooltip {
  @include mq(l) {
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: content-box;
    width: col(3, 8);
    padding: 0 0 0 col(1, 8);
    transform: translateX(100%);
  }
}

.form-sidetooltip__trigger {
  @extend %button-nostyle;

  position: absolute;
  top: 0;
  right: 0;

  .label {
    @extend %visually-hidden;
  }

  .icon {
    color: $c-mustard;
  }

  @include mq(l) {
    display: none;
  }
}

.form-sidetooltip__tip-outer {
  position: relative;

  @include mq($until: l) {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
    visibility: hidden;
    transition: background 0.5s 0.2s;

    .is-open & {
      background: rgba($c-black, 0.8);
      pointer-events: auto;
      visibility: visible;
      transition: background 0.5s 0s;
    }
  }
}

.form-sidetooltip__tip {
  @include mq($until: l) {
    position: relative;
    z-index: 1;
    min-width: 30rem;
    margin: $spacing;
    padding: $spacing;
    background: $c-white;
    border-radius: 0.8rem;
    opacity: 0;
    transform: translateY(20px);
    transition: 0.5s 0s;

    .is-open & {
      opacity: 1;
      transform: translateY(0);
      transition: 0.5s 0.2s;
    }
  }
}

.form-sidetooltip__tip__content-outer {
  @extend %list-nostyle;
}

.form-sidetooltip__tip__content {
  .icon {
    margin-bottom: $spacing;
    color: $c-mustard;
  }

  & + & {
    margin-top: $spacing;
  }

  @include mq(l) {
    display: flex;
    align-items: flex-start;

    .icon {
      flex-shrink: 0;
      margin: 0 $spacing 0 0;
      color: $c-mustard;
    }

    .small {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
    }
  }
}

.form-sidetooltip__tip__button {
  @extend %button-nostyle;

  position: absolute;
  right: $spacing;
  line-height: 2.4rem;

  .icon {
    margin-left: $spacing * 0.5;
  }

  @include mq(l) {
    display: none;
  }
}
