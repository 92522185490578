
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































$bp: s;

.benefits {
  list-style-type: none;

  @include mq($bp) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: $spacing * 0.5;

    &.even {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $spacing * 0.5;
    }
  }

  @include mq(m) {
    &.even {
      grid-template-columns: repeat(4, 1fr);
      gap: $spacing;
    }
  }
}

.benefits__item {
  display: flex;
  align-items: center;

  & + & {
    margin-top: $spacing * 0.5;
  }

  @include mq($bp) {
    max-width: calc(calc(100% - #{$spacing}) / 3);

    & + & {
      margin-top: 0;
    }

    .even & {
      max-width: none;
      margin-left: 0;
    }
  }
}

.benefits__icon {
  flex-shrink: 0;
  width: 3.6rem;
  height: 3.6rem;
  margin-right: $spacing * 0.5;

  @include mq($bp) {
    width: 6rem;
    height: 6rem;
  }
}

.benefits__text {
  line-height: 1.2;
}
