
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































.contact-showroom {
  width: 100%;
  padding: $spacing * 2 0;
  // visibility: hidden;

  // &.is-visible {
  //   visibility: visible;
  // }

  @include mq(m) {
    display: flex;
  }
}

.contact-showroom__picture {
  height: 25rem;

  @include mq(m) {
    flex: 1;
    width: 45%;
    height: auto;
  }
}

.contact-showroom__content {
  position: relative;
  margin: $spacing auto 0;
  // transform: translateY(50%);
  // opacity: 0;
  transition: 0.3s;
  transition-property: transform, opacity;

  // .is-visible & {
  //   opacity: 1;
  //   transform: translateY(0);
  // }

  @include mq(m) {
    flex: 1;
    margin-top: 0;
    margin-left: $spacing * 3.5;
  }
}

.contact-showroom__content__distance {
  @extend %fw-bold;

  position: absolute;
  top: 0;
  right: 0;
  color: $c-gray-medium;
  text-transform: uppercase;

  &::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 1.5rem;
    margin-right: $spacing;
    background: $c-gray-light;
  }
}

.contact-showroom__content__text {
  margin: $spacing 0;
}

.contact-showroom__content__adress {
  margin: $spacing 0;
  line-height: 1.5;

  a {
    text-decoration: none;
  }
}
