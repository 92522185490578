
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































































































.form-group {
  width: 100%;

  @include mq(xs) {
    grid-column: 1 / 3;
  }
}

.form-label {
  @extend %ff-alt;

  margin-bottom: 1rem;
  color: $c-gray-dark;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  @include mq(m) {
    font-size: 1.3rem;
  }
}

.form-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  text-align: center;
  border: 1px solid $c-light-grey;
  border-radius: 4px;
}

.form-area__info {
  @extend %fw-medium;

  color: $c-medium-grey;

  &.selected {
    @extend %fw-bold;

    color: $c-dark-grey;
  }
}

.form-area__info__number {
  padding-right: 0.3rem;
  color: $c-mustard;
  font-size: 3.6rem;
}
