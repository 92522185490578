
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































































































































.form-categories {
  margin-inline: 0;
  padding: 0;
  border: 0;
}

.form-categories__label {
  @extend %ff-alt;

  margin-bottom: var(--spacing-s);
  color: $c-gray-dark;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  span {
    color: var(--c-primary);
  }

  @include mq(m) {
    font-size: 1.3rem;
  }
}

.form-categories__item {
  display: inline-block;
  margin: 0 1rem 1rem 0;
}

.form-categories__item__input {
  @extend %visually-hidden;
}

.form-categories__item__label {
  @extend %ff-alt;
  @include fluid(
    font-size,
    (
      xxs: 14px,
      xxl: 18px,
    )
  );

  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: fit-content;
  padding: 1rem 2rem;
  text-align: center;
  background: transparent;
  border: 0.1rem solid var(--c-primary);
  border-radius: 3rem;
  transition: background 0.75s $ease-80-20;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background: var(--c-primary-20);
  }

  input[checked] + &,
  input:checked + & {
    color: $c-white;
    background: var(--c-primary);
  }
}
