
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























































































































.contact-steps {
  list-style-type: none;

  @include mq(m) {
    display: flex;
    justify-content: space-between;
  }
}

.contact-steps__item {
  @include mq(m) {
    width: (math.div(90%, 3));

    & + & {
      margin-left: 5%;
    }
  }
}

.contact-steps__step {
  @include mq($until: m) {
    display: none;
    margin-top: $spacing * 1.5;

    &.is-active {
      display: block;
    }
  }
}
