
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































































































.contact-form__title {
  @extend %text-center;

  margin-top: $spacing * 2;

  @include mq(m) {
    margin-top: $spacing * 3;
  }

  @include mq(xl) {
    margin-top: $spacing * 4.5;
  }
}

.contact-form__subtitle {
  @extend %text-center;

  margin-top: $spacing;
}

.form {
  margin-top: $spacing * 2;

  @include mq(m) {
    margin-top: $spacing * 3;
  }

  @include mq(xl) {
    margin-top: $spacing * 4;
  }
}

.form-inner {
  @include mq(m) {
    padding: 0 col(2, 12);
  }

  @include mq(xl) {
    padding: 0 col(3, 12);
  }
}

.form__fieldset {
  position: relative;
  margin-left: 0;
  padding: 0;
  border: 0;
}

.form__input__error {
  bottom: -3rem;
}

.form__fieldset,
.form__confirmation {
  @include fluid(
    column-gap,
    (
      xxs: 10px,
      xxl: 35px,
    )
  );
  @include fluid(
    row-gap,
    (
      xxs: 20px,
      xxl: 25px,
    )
  );

  margin-top: 0;

  @include mq('s') {
    display: grid;
    grid-template: auto / 1fr 1fr;
  }
}

.form__input {
  margin-top: $spacing;

  &.full {
    grid-column: 1 / 3;
  }
}

.form__confirmation {
  margin-left: 0;
  padding: 0;
  border: 0;

  @include mq('s') {
    display: block;
  }

  @include mq('xl') {
    grid-template: auto / 1fr 300px;
    row-gap: 0;
  }
}

.form__send {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: $spacing;
  margin-top: $spacing * 3;
  padding: 0;
  border: 0;

  .form-feedback {
    position: static;
    flex: 1;
  }

  @include mq(l) {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
}

.form-label__required {
  color: $c-mustard;
}

.form__feedback {
  width: 100%;
}
