
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































































































































































































































































































































































































































































































































.form {
  @include mq(l) {
    padding-right: col(4, 12);
  }
}

::v-deep .form-label {
  @include mq(l) {
    display: block;
    margin-bottom: 0;
    line-height: 2rem;
  }
}

::v-deep .form-sidetooltip {
  @include mq(l) {
    top: 3rem;
  }
}

.form__fieldset {
  margin: 0 0 $spacing * 2 0;
  padding: 0;
  border: 0;
}

.form__input {
  position: relative;

  & + & {
    margin-top: $spacing;
  }

  @include mq(s) {
    & + & {
      margin-top: $spacing * 2.25;
    }
  }
}

.form__feedback {
  @extend %border-radius-small;

  position: static;
  justify-content: center;
  width: 100%;
  padding: $spacing $spacing $spacing * 1.2;
  font-size: 1.5rem;
  border: 1px solid $c-dev-error;

  & + & {
    margin-top: $spacing;
  }
}

.form__send {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: $spacing * 2;
  padding: $spacing * 2 0;
  border: 0;
  border-top: 1px solid $c-gray-light;

  @include mq(s) {
    flex-direction: row;
    justify-content: space-between;
  }

  @include mq(l) {
    padding: $spacing * 2.4 0;
  }
}

.form__send__action {
  margin-top: $spacing;

  & + & {
    margin-top: 0;
  }

  @include mq(s) {
    margin-top: 0;
  }
}

.form__projects,
.form__files {
  margin: $spacing * 1.25 0 0;
}

.form-loading {
  margin-bottom: $spacing;
  text-align: center;

  @include mq(s) {
    display: inline-block;
    margin: 0 $spacing 0 0;
  }
}

.form-required {
  color: var(--c-primary);
}
